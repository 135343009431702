var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-navigation-drawer',{attrs:{"app":"","temporary":"","light":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"py-2"},[_c('v-btn',{staticStyle:{"height":"100px"},attrs:{"text":""},on:{"click":function($event){return _vm.goTo('Home')}}},[_c('img',{attrs:{"src":require("@/assets/conasi_logo.png"),"alt":"Logo","width":"200px","height":"50px"}})])],1),_c('v-divider')],1),_c('v-app-bar',{attrs:{"app":"","light":"","height":"80px"}},[(_vm.isXs)?_c('v-app-bar-nav-icon',{staticClass:"mr-4",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_c('div',{staticClass:"d-flex justify-lg-space-between align-center w-100"},[_c('v-toolbar-title',{staticClass:"cursor-pointer",staticStyle:{"flex":"0.33"}}),_c('div',{staticStyle:{"flex":"0.33"}},[_c('v-btn',{staticStyle:{"height":"auto"},attrs:{"text":""},on:{"click":function($event){return _vm.goConasi365()}}},[_c('img',{attrs:{"src":require("@/assets/conasi_logo.png"),"alt":"Logo","width":"240px","height":"55px"}})])],1),_c('div',{staticStyle:{"flex":"0.33","text-align":"right"}},[_c('span',{staticClass:"px-2 cursor-pointer",on:{"click":_vm.onChangeLocale}},[_vm._v(_vm._s(_vm.$i18n.locale === "en" ? "VI" : "EN"))]),_c('v-menu',{staticClass:"px-2",attrs:{"close-on-content-click":false,"nudge-width":350,"open-on-hover":"","bottom":"","right":"","transition":"slide-y-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"px-2"},'v-icon',attrs,false),on),[_vm._v("mdi-account-circle")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',{staticClass:"text-left"},[_c('v-list-item-avatar',[_c('avatar',{attrs:{"username":_vm.getCurrentLoginName()}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("navigation.account")))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.getCurrentLoginName()))])],1)],1)],1),_c('v-divider'),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"error"},on:{"click":_vm.onLogOut}},[_vm._v(" "+_vm._s(_vm.$t("common.logout"))+" ")])],1)],1)],1)],1)],1)],1)],1),_c('v-main',[_c('v-container',{style:(("width: " + (_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
          ? ''
          : '1200px !important') + ";"))},[_c('div',[_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"text-h6 pa-3"},[_vm._v(_vm._s(_vm.$t("reportStatistic.title", { '0': _vm.datePicker})))])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}}}),{key:"item.localText",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t("reportStatistic." + item.localText))+" ")]}}],null,true)})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }