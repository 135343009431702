<template>
  <div>
    <div>
      <v-navigation-drawer v-model="drawer" app temporary light>
        <div class="py-2">
          <v-btn text @click="goTo('Home')" style="height: 100px">
            <img
              src="@/assets/conasi_logo.png"
              alt="Logo"
              width="200px"
              height="50px"
            />
          </v-btn>
        </div>
        <v-divider />
      </v-navigation-drawer>
      <v-app-bar app light height="80px">
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="mr-4"
          v-if="isXs"
        />
        <div v-else class="d-flex justify-lg-space-between align-center w-100">
          <v-toolbar-title class="cursor-pointer" style="flex: 0.33">
          </v-toolbar-title>
          <div style="flex: 0.33">
            <v-btn text @click="goConasi365()" style="height: auto">
              <img
                src="@/assets/conasi_logo.png"
                alt="Logo"
                width="240px"
                height="55px"
              />
            </v-btn>
          </div>
          <div style="flex: 0.33; text-align: right">
            <span class="px-2 cursor-pointer" @click="onChangeLocale">{{
              $i18n.locale === "en" ? "VI" : "EN"
            }}</span>
            <v-menu
              class="px-2"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="350"
              open-on-hover
              bottom
              right
              transition="slide-y-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" class="px-2"
                  >mdi-account-circle</v-icon
                >
              </template>

              <v-card>
                <v-list>
                  <v-list-item class="text-left">
                    <v-list-item-avatar>
                      <!-- <img
                        src="https://cdn.vuetifyjs.com/images/john.jpg"
                        alt="John"
                      /> -->
                      <avatar :username="getCurrentLoginName()" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("navigation.account")
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        getCurrentLoginName()
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <!-- <v-list>
                  <v-list-item @click="onGoToPage('Profile')">
                    <v-list-item-action>
                      <v-icon>mdi-account</v-icon>
                    </v-list-item-action>
                    <v-list-item-title class="text-left">{{
                      $t("navigation.myAccount")
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list> -->

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed color="error" @click="onLogOut">
                    {{ $t("common.logout") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
        </div>
      </v-app-bar>
    </div>
    <v-main>
      <v-container
        :style="`width: ${
          $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
            ? ''
            : '1200px !important'
        };`"
      >
        <!-- <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 sm8 md4></v-flex>
            <v-flex xs12 sm8 md4></v-flex>
            <v-flex xs12 sm8 md4>
              <v-menu
                ref="menu"
                :close-on-content-click="false"
                v-model="menuDatePicker"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateFormatted"
                    :label="$t('common.year')"
                    persistent-hint
                    prepend-icon="mdi-calendar-month"
                    @blur="date = parseDate(dateFormatted)"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="datePicker"
                  no-title
                  @input="menu = false"
                  active-picker="YEAR"
                  :show-current = "true"
                />
              </v-menu>
            </v-flex>
          </v-layout>
        </v-form> -->
        <div>
          <v-card>
            <v-card-text>
              <div class="text-h6 pa-3">{{ $t("reportStatistic.title", { '0': datePicker}) }}</div>
            </v-card-text>
            <v-data-table
              :headers="headers"
              :items="desserts"
              hide-default-footer
              hide-default-header
              class="elevation-1"
            >
              <template
                v-for="h in headers"
                v-slot:[`header.${h.value}`]="{ header }"
              >
                {{ $t(header.text) }}
              </template>
              <template v-slot:[`item.localText`]="{ item }">
                {{ $t("reportStatistic." + item.localText) }}
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-container>
    </v-main>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import functionUtils from "Utils/functionUtils";
import { isCookieEnabled, getCookie, removeCookie } from "tiny-cookie";
import Avatar from 'vue-avatar'
export default {
  components: {
    Avatar
  },
  data: () => ({
    products: 0,
    drawer: null,
    isXs: false,
    logoUrl: null,
    color: null,
    fav: true,
    menu: false,
    message: false,
    hints: true,
    //
    headers: [
      { text: "", value: "localText" },
      { text: "", value: "value" },
    ],
    desserts: [
      { localText: "numberUserLastLogin", value: null },
      { localText: "numberEntity", value: null },
      { localText: "numberNewEntity", value: null },
      { localText: "totalProduct", value: null },
      { localText: "totalNewProduct", value: null },
      { localText: "totalProductOrder", value: null },
      { localText: "totalProductOrderComplete", value: null },
      { localText: "totalProductOrderCancel", value: null },
      { localText: "totalFeeOrder", value: null },
    ],
    // 
    dateFormatted: null,
    datePicker: null,
    menuDatePicker: false
  }),
  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
    GET_REPORT_STATISTIC_DATA: function () {
      let data = this.GET_REPORT_STATISTIC_DATA;
      this.desserts.forEach((e) => {
        if (e.localText in data) {
          e.value = data[e.localText];
        }
      });
    },
    datePicker () {
      this.dateFormatted = this.formatYear(this.datePicker)
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatYear(this.datePicker)
    },
    ...mapGetters(["CURRENT_ACCOUNT_DATA", "GET_REPORT_STATISTIC_DATA"]),
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    const userId = isCookieEnabled() ? getCookie("userReportId") : null;
    this.GET_CURRENT_ACCOUNT({ id: userId });
    this.datePicker = new Date().getFullYear()
    this.getReportStatistic();
  },
  methods: {
    formatYear (date) {
      if (!date) return null
      const [year] = date.split('-')
      return `${year}`
    },
    /**
     * Parse date
     */
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    getCurrentLoginName: function () {
      return this.CURRENT_ACCOUNT_DATA
        ? functionUtils.concatFullname(
            this.CURRENT_ACCOUNT_DATA.last_name,
            this.CURRENT_ACCOUNT_DATA.first_name
          )
        : "";
    },
    goConasi365: function () {
      window.open("https://conasi365.vn/");
    },
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    /**
     * Change locale
     */
    onChangeLocale() {
      let locale = this.$i18n.locale === "en" ? "vi" : "en";
      this.CHANGE_LOCALE(locale);
    },
    /**
     * Log out
     */
    onLogOut: function () {
      let token = isCookieEnabled()
        ? getCookie("report_statistic_token")
        : null;
      this.LOGOUT(token).then(
        function () {
          this.$toast.open({
            message: this.$t("common.success"),
            type: "success",
            ...this.$toastConfig,
          });
          this.$router.push({
            name: "ReportStatisticLogin",
          });
          removeCookie("report_statistic_token");
          removeCookie("userReportId");
        }.bind(this)
      );
    },
    onGoToPage: function (name) {
      this.$router.push({
        name: name,
      });
    },
    getReportStatistic: function () {
      let data = {
        params: {
          year: this.datePicker
        }
      }
      this.GET_REPORT_STATISTIC(data)
        .then(function () {}.bind(this))
        .catch(
          function (error) {
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText;
              this.$toast.open({
                message: errorText,
                type: "error",
                ...this.$toastConfig,
              });
            } else {
              this.$toast.open({
                message: this.$t("common.somethingWentWrong"),
                type: "error",
                ...this.$toastConfig,
              });
            }
          }.bind(this)
        );
    },
    ...mapActions([
      "CHANGE_LOCALE",
      "LOGOUT",
      "GET_CURRENT_ACCOUNT",
      "GET_REPORT_STATISTIC",
    ]),
  },
};
</script>
<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
</style>
